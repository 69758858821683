import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import { SpinnerCircular } from "spinners-react";
import { upload_icon } from "../../../constant";

const CreateProfile = (props) => {
  const {
    NextStep,
    handleImageUpload,
	fileupload,
    profileData,
    setProfileData,
    handleChange,
    CreateProfileHandler,
    step3Loader,
  } = props;
  const [images, setImages] = React.useState([]);

  const maxNumber = 69;
  const onChange = (e, imageList, addUpdateIndex) => {
    e.preventDefault();
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  const stopsubmit = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div className="login-card-wrapper Create-Your-Profile ">
        <div className="login-heading-wrapper">
          <h3>Create Your Profile</h3>
        </div>
        <div className="login-form-wrapper mt-5">
          <form action="">
            <div className="form-group">
              <div className="img-wrapper text-center">
               
              <div className="upload-profile">
			  {fileupload ? (
                  <img src={fileupload} className="img-fluid" alt="" />
                ) : null}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  multiple={false}
                  className="profile-upload"
                />
				<img src={upload_icon} className="upload-icon"/>
                {/* <BsFillCameraFill className="icon" /> */}
              </div>
			  </div>
            </div>
            <div className="form-group ">
              <input
                type="text"
                placeholder="User name"
                className="form-control"
                value={profileData?.username}
                onChange={handleChange}
                name="username"
                required
              />
            </div>
            <div className="form-group ">
              <input
                type="password"
                placeholder="Password"
                className="form-control"
                value={profileData?.password}
                onChange={handleChange}
                name="password"
                required
              />
            </div>
            <div className="form-group ">
              <input
                type="password"
                placeholder="Confirm Password"
                className="form-control"
                value={profileData?.confirm_password}
                onChange={handleChange}
                name="confirm_password"
                required
              />
            </div>
            <div className="form-group text-center">
              <button onClick={(e) => CreateProfileHandler(e)} className="btn"
              disabled={step3Loader}
              >
               {step3Loader ? <SpinnerCircular size="20px" color="#fff" /> : "Next"} 
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateProfile;
