import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { login_header_logo } from "../../constant";
import ConfirmEmail from "./AccountStep/ConfirmEmail";
import CreateProfile from "./AccountStep/CreateProfile";
import CreateProfilesStep4 from "./AccountStep/CreateProfilesStep4";
import BecomeMember from "./AccountStep/BecomeMember";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { ApiUrl } from "../../network/ApiUrl";
import { doPost } from "../../network/Config";
import {
  postSignupStep1,
  postSignUpStep3Api,
  postSignupStep4,
} from "../../network/Network";
import { SpinnerCircular } from "spinners-react";
import { signUp, StoreStep } from "../../redux/actions/AuthActions";

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
  "Create an addg",
];

const CreateAccount = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [step1Loader, setStep1Loader] = useState(false);
  const [step3Loader, setStep3Loader] = useState(false);
  const [step4Loader, setStep4Loader] = useState(false);
  const [activeStep, setActiveStep] = React.useState(
    localStorage.getItem("stepNo") ? localStorage.getItem("stepNo") : 1
  );
  const [skipped, setSkipped] = React.useState(new Set());
  const [page, setPage] = useState(1);
  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [strain, setStrain] = useState("");
  const [selectedInterest, setSelectedInterest] = useState();
  let userData = JSON.parse(localStorage.getItem("user"));

  const [profileData, setProfileData] = useState({
    username: userData?.full_name ? userData?.full_name : "",
    password: "",
    confirm_password: "",
  });

  console.log(
    "Local",
    localStorage.getItem("stepNo"),
    localStorage.getItem("user_Token"),
    localStorage.getItem("user")
  );

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (No) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => parseInt(prevActiveStep) + 1);
    setSkipped(newSkipped);
    localStorage.setItem("stepNo", No + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const SignUpHandler = async (e) => {
    setStep1Loader(true);
    e.preventDefault();
    if (!firstName || !lastName || !email || !dob || !gender || !gender) {
      toast.error("Please enter all fields");
      setStep1Loader(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setStep1Loader(false);
      return;
    } else {
      var data = new FormData();
      data.append("first_name", firstName);
      data.append("last_name", lastName);
      data.append("email", email);
      data.append("dob", dob);
      data.append("gender", gender);
      console.log("complete Data", data);
      let send = await dispatch(
        signUp(data, Navigate, setStep1Loader, handleNext)
      );
    }
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    setUploadLoading(true);
  };

  console.log(newFile)

  function handleChange(e) {
    const value = e.target.value;
    setProfileData({
      ...profileData,
      [e.target.name]: value,
    });
  }

  const CreateProfileHandler = (e) => {
    e.preventDefault();
    setStep3Loader(true);
    if (!profileData?.password || !profileData?.confirm_password) {
      toast.error("Please enter all fields");
      setStep3Loader(false);
      return;
    }
    var data = new FormData();
    data.append("user_id", userData?.id);
    data.append("username", profileData?.username);
    data.append("password", profileData?.password);
    data.append("confirm_password", profileData?.confirm_password);
    data.append("profile_image",newFile);
    postSignUpStep3Api(data)
      .then((res) => {
        console.log("profile create", res);
        toast.success(res?.data?.message);
        setStep3Loader(false);
        handleNext(3);
      })
      .catch((err) => {
        console.log(err);
        setStep3Loader(false);
        if (err?.response?.data?.errors?.confirm_password[0]) {
          toast.error(err?.response?.data?.errors?.confirm_password[0]);
        }
      });
  };

  const CreateProfileStep4Handler = (e) => {
    e.preventDefault();
    setStep4Loader(true);
    if (!strain) {
      toast.error("Please enter strain");
      setStep4Loader(false);
      return;
    }
    if (!selectedInterest) {
      toast.error("Please select interest");
      setStep4Loader(false);
      return;
    }
    let Token = localStorage.getItem("user_Token");
    let data = {
      strain: strain,
      interest: selectedInterest,
    };
    postSignupStep4(data, Token)
      .then((res) => {
        console.log("lasst step", res);
        toast.success(res?.data?.message);
        setStep4Loader(false);
        Navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        setStep4Loader(false);
      });
  };

  console.log(profileData);

  console.log("active", activeStep);
  return (
    <>
      {/* header logo starts here */}
      <header className="login-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="header-logo-wrapper">
                <figure>
                  <img src={login_header_logo} alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* header logo ends here */}
      {/* login starts here */}
      <section className="login-sec Create-account">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode,
                    } = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography variant="caption">Optional</Typography>
                      );
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        {/* <StepLabel {...labelProps}>{label}</StepLabel> */}
                      </Step>
                    );
                  })}
                </Stepper>
                {/* {activeStep === steps.length ? (
									<React.Fragment>
										<BecomeMember />
									</React.Fragment>
								) : ( */}
                <React.Fragment>
                  {activeStep == 1 ? (
                    <div className="login-card-wrapper">
                      <div className="login-heading-wrapper">
                        <h3>Create Your Account</h3>
                      </div>
                      <div className="login-form-wrapper mt-5">
                        <form action="">
                          <div className="form-group row">
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First name"
                                value={firstName}
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last name"
                                required
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6">
                              <input
                                type="date"
                                className="form-control"
                                placeholder="D.O.B"
                                required
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                              />
                            </div>
                            <div className="col-lg-6">
                              <select
                                onChange={(e) => setGender(e.target.value)}
                                name=""
                                required
                                className="form-control"
                                id=""
                              >
                                <option defaultValue="" selected>
                                  Gender (Optional)
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6">
                              <input
                                type="Email"
                                className="form-control"
                                required
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              <p>
                                <>You will confirm this in next step</>
                              </p>
                            </div>
                            <div className="col-lg-6">
                              <button
                                onClick={(e) => SignUpHandler(e)}
                                type="submit"
                                className="btn"
                                disabled={step1Loader}
                              >
                                {step1Loader ? (
                                  <SpinnerCircular size="20px" color="#fff" />
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="form-group"></div>
                        </form>
                      </div>
                    </div>
                  ) : activeStep == 2 ? (
                    <ConfirmEmail NextStep={handleNext} />
                  ) : activeStep == 3 ? (
                    <CreateProfile
                      handleImageUpload={handleImageUpload}
                      fileupload={fileupload}
                      profileData={profileData}
                      setProfileData={setProfileData}
                      handleChange={handleChange}
                      CreateProfileHandler={CreateProfileHandler}
                      step3Loader={step3Loader}
                    />
                  ) : (
                    <CreateProfilesStep4
                      strain={strain}
                      setStrain={setStrain}
                      selectedInterest={selectedInterest}
                      setSelectedInterest={setSelectedInterest}
                      CreateProfileStep4Handler={CreateProfileStep4Handler}
                      step4Loader={step4Loader}
                    />
                  )}
                  {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
											<Button
												color="inherit"
												disabled={activeStep === 0}
												onClick={handleBack}
												sx={{ mr: 1 }}
											>
												Back
											</Button>

											<Box sx={{ flex: "1 1 auto" }} />
											{isStepOptional(activeStep) && (
												<>
													<Button
														color="inherit"
														onClick={handleSkip}
														sx={{ mr: 1 }}
													>
														Skip
													</Button>
												</>
											)}
											<Button onClick={handleNext}>
												{activeStep === steps.length - 1 ? "Finish" : "Next"}
											</Button>
										</Box> */}
                </React.Fragment>
                {/* )} */}
              </Box>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      {/* login ends here */}
    </>
  );
};

export default CreateAccount;
