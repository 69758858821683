import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  attchment_img,
  gallery_btn,
  profile_img,
  video_btn,
} from "../../constant";
import { ChromePicker } from "react-color";
import { SpinnerCircular } from "spinners-react";

function CreatePostModal(props) {
  const {
    setIsOpenCreatePostModal,
    isOpenCreatePostModal,
    createLoading,
    MediaUploadHandler,
    CreatePostHandler,
    caption,
    setCaption,
    multiMediaFiles,
    media,
    is_comment,
    setIs_comment,
    filesContent,
    setBackopen,
    backopen,
    openFileSelector,
    fontcolor,
    setFontcolor,
    fontopen,
    setFontopen,
    color,
    setColor,
  } = props;

  const renderMedia = (source) => {
    return source?.map((item) => {
      return (
        <>
        {
          item?.type == "image/png" ? (
            <img
            src={URL.createObjectURL(item)}
            alt=""
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
          ) : (
            <>
              {
              item?.type == "video/mp4" ? (
                <video controls style={{ width: "100px", height: "100px", objectFit: "cover" }}>
                    <source src={URL.createObjectURL(item)} type="video/mp4" />
                 </video>
              ) : (null)
            }
            </>
          )
        }
          
        </>
      );
    });
  };

  console.log("is_comment",is_comment)
  return (
    <>
      <Modal
        isOpen={isOpenCreatePostModal}
        toggle={() => {
          setIsOpenCreatePostModal(false);
        }}
        className="Create-Post"
      >
        {/* <Modal className="Create-Post" show={show} onHide={handleClose}> */}
        <ModalHeader closeButton={"hvbhy"}>
          Create post
          {/* <Modal.Title>Create post</Modal.Title> */}
        </ModalHeader>
        <ModalBody>
          <div className="Post-Modal-sec">
            <div className="modal-profile-wrapper">
              <div className="modal-img-wrapper">
                <img src={profile_img} alt="" />
              </div>
              <div className="profile-content-wrapper">
                <h5>Darrell Bailey</h5>
                <select name="" id="">
                  <option value="Public">
                    <i className="fa fa-globe" aria-hidden="true"></i> Public{" "}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="mind-sec-wrapper">
            <div className="mind-field-wrapper">
              <textarea
                style={{ backgroundColor: color, color: fontcolor }}
                placeholder="What's on your mind, Darell?"
                className="form-control"
                maxlength="500"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              ></textarea>
              <div className="color-picker">
                <div
                  className="left-side"
                  onMouseLeave={() => setBackopen(false)}
                >
                  <button
                    onClick={() =>
                      backopen ? setBackopen(false) : setBackopen(true)
                    }
                  >
                    Select background color
                  </button>
                  <div
                    className="background-picker"
                    style={{ display: backopen ? "block" : "none" }}
                  >
                    <ChromePicker
                      color={color}
                      onChangeComplete={(color) => {
                        setColor(color?.hex);
                      }}
                    />
                  </div>
                </div>
                <div
                  className="right-side"
                  onMouseLeave={() => setFontopen(false)}
                >
                  <button onClick={() => setFontopen(true)}>
                    Select font color
                  </button>
                  <div
                    className="font-picker"
                    style={{ display: fontopen ? "block" : "none" }}
                  >
                    <ChromePicker
                      color={fontcolor}
                      onChangeComplete={(color) => {
                        setFontcolor(color?.hex);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="bottom-bar">
                <div className="comments">
                  <label className="switch">
                    <input type="checkbox" 
                      // value={is_comment}
                       onChange={(e) =>setIs_comment(!is_comment)}
                    />
                    <span className="slider">
                      <p>comment disable/enable</p>
                    </span>
                  </label>
                </div>
                <div className="count">
                  <p>500 word limit</p>
                </div>
              </div>
            </div>
            <div className="add-to-post">
              <div className="post-heading">
                <h6>Add to your post</h6>
              </div>
              <div className="attachments-wrapper">
                <ul>
                  <li>
                    <div className="file-wrapper">
                      <button
                      // onClick={() => openFileSelector()}
                      >
                        <img src={video_btn} />
                        <input
                          type="file"
                          accept="video/*"
                          onChange={MediaUploadHandler}
                          multiple={true}
                          id="fileUpload"
                        />
                      </button>
                    </div>
                  </li>
                  <li>
                    <div className="file-wrapper">
                      <button
                      // onClick={() => openFileSelector()}
                      >
                        <img src={gallery_btn} />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={MediaUploadHandler}
                          multiple={true}
                          id="fileUpload"
                        />
                      </button>
                    </div>
                  </li>
                  <li>
                    <div className="file-wrapper">
                      <button
                      // onClick={() => openFileSelector()}
                      >
                        <img src={attchment_img} />
                        <input
                          type="file"
                          onChange={MediaUploadHandler}
                          multiple={true}
                          id="fileUpload"
                        />
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="post-btn-wrapper">
              <button
                className="btn  form-control"
                onClick={(e) => CreatePostHandler(e)}
                disabled={createLoading}
              >
                {createLoading ? (
                  <SpinnerCircular size="20px" color="#fff" />
                ) : (
                  "Post"
                )}
              </button>
            </div>
          </div>

          <br />

          {/* {filesContent?.map((file, index) => (
    <>
      {console.log(file)}
      <div key={index}>
        <h2>{file.name}</h2>
        <div key={index}>{file.content}</div>
        <img source={{ uri: Base64.decode(file.content) }} />
        <br />
      </div>
    </>
  ))} */}

          {renderMedia(media)}
        </ModalBody>
        {/* </Modal> */}
      </Modal>
    </>
  );
}

export default CreatePostModal;
