import React, { useEffect, useState } from "react";
import { SpinnerCircular } from "spinners-react";
import { GetInterestList } from "../../../network/Network";

const CreateProfilesStep4 = (props) => {
  const {
    strain,
    setStrain,
    selectedInterest,
    setSelectedInterest,
    CreateProfileStep4Handler,
    step4Loader,
  } = props;

  const [interestData, setInterestData] = useState([]);
  useEffect(() => {
    GetInterestList()
      .then((res) => {
        console.log("interest list", res);
        setInterestData(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <>
        <div className="login-card-wrapper Create-Your-Profile ">
          <div className="login-heading-wrapper">
            <h3>Create Your Profile</h3>
          </div>
          <div className="login-form-wrapper mt-5">
            <form action="">
              <div className="form-group ">
                <input
                  type="text"
                  placeholder="Favorite Strain*"
                  className="form-control"
                  value={strain}
                  onChange={(e) => setStrain(e.target.value)}
                />
              </div>
              <div className="form-group ">
                <select
                  name=""
                  className="form-control"
                  id=""
                  onChange={(e) => {
                    setSelectedInterest(e.target.value);
                  }}
                >
                  <option selected=" "> Select Cannabis Interests</option>
                  {interestData?.map((item, index) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.title}
                      </option>
                    );
                  })}
                </select>
                <p>Select cannabis interests</p>
              </div>
              <div className="form-group text-center">
                <button
                  onClick={(e) => CreateProfileStep4Handler(e)}
                  className="btn"
                  disabled={step4Loader}
                >
                  {step4Loader ? (
                    <SpinnerCircular size="20px" color="#fff" />
                  ) : (
                    "Finish"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
};

export default CreateProfilesStep4;
