// Define api end points here

export const AUTH = "/auth";
export const STORE = "/store";
export const USER = "/user";
export const STORE_STEP2 = "/store_step2";
export const SHOW_INTEREST = "/showInterest";
export const INTEREST = "/interest";
export const UPDATE_STEP4 = "/updateStep4";
export const SITE = "/site";
export const INFO = "/info";
export const UPDATE = "/update";
export const POST = "/post";
export const INDEX = "/index";
export const FEEDS = "/feeds";


// ents 



export const REGISTER = "/register";
export const FORGOT = "/forgot-password";
export const BLOGS = "/blogs";
export const GET_KIGI_FAQS = "/getKigiFaqs";
export const ADD = "/add";
export const NEWS_LETTER = "/news-letter";
export const KIGI_NURSERY = "/kigi-nursery";
export const GET_KIGI_SITESETTINGS = "/getKigiSiteSettings";

export const CUSTOMER = "/customer";
export const WISH_LIST = "/wish-list";
export const REMOVE = "/remove";
export const CATEGORIES = "/categories";
export const URLS = "/urls";
export const BRANDS = "/brands";
export const CAMPAIGN = "/campaign";
export const GET_CAMPAIGN = "/getCampaign";
export const FIND_CAMPAIGN = "/findCampaign";

export const DONATE = "/donate";
export const MAKE_DONATION = "/makeDonation";
export const MEMBERSHIP = "/membership";
export const GET_MEMBERSHIP = "/getMemberships";

export const FIND_MEMBERSHIP = "/findMembership";
export const CSA_PRODUCT_PRICE = "/csa-product-price";

export const PRODUCTS = "/products";
export const DETAILS = "/details";

export const SUBMIT = "/submit";
export const REVIEWS = "/reviews";
export const RATING = "/rating";

export const UPDATE_PROFILE = "/update-profile";
export const ADDRESS = "/address";
export const LIST = "/list";
export const CHANGE_PASSWORD = "/change-password";
export const BLOG_DETAILS = "/blog-details";
export const CONTACT = "/contact";
export const CONTACTUS = "/contactUs";
export const GALLERY = "/gallery";
export const GET_IMAGES = "/getImages";
export const FILTER_TYPES = "/filter_types";

export const GIFT_CODE = "/gift-code";
export const GET_GIFT_CODE = "/getGiftCode";
export const SEND_GIFT_CODE = "/sendGiftCode";
export const GIFT_THEME = "/gift-theme";
export const GET_GIT_THEME = "/getGiftTheme";
export const GET_VIDEOS = "/getVideos";
export const MY_DONATION = "/my-donation";
export const MAKE_DEFAULT = "/make-default";
export const DELETE = "/delete";

export const COUPON = "/coupon";
export const APPLY = "/apply";
export const PLACE = "/place";
export const ORDER = "/order";

export const RESET_PASSWORD = "/reset-password";
export const SERVICES = "/services";
export const GET_SERVICES = "/getServices";

export const HOW_IT_WORKS = "/how-it-works";
export const GET_HOME_IT_WORKS = "/getHowItWorks";
export const PRODUCT_DATE = "/product-date";
export const GET_KIGI_SOCIAL_MEDIA = "/getKigiSocialMedia";
export const FREQUENTLY_BOUGHT = "/frequently-bought";
export const EDIT_ADDRESS = "/edit-address";

// HOHO END 


export const LOGIN = "/login";



export const AVAILABLE_FRANCHISES = "/available-franchises";
export const SITE_SETTINGS = "/site-settings"
export const PROFILE = "/profile"


export const FRANCHISE = "/franchises";
export const CREATE = "/create";
export const DETAIL = "/detail";
export const STATUS = "/status";

export const LEADS = "/leads";
export const PURCHASE = "/purchase";
export const INVESTMENT_LEVELS = "/investment-levels";
export const CARDS = "/cards";
export const PAGES = "/pages";
export const LEAD = "/lead";




// End Franchise 


export const PACKAGE = "/package";
export const CAR = "/car";
export const CONDITION = "/condition";

export const OPTIONS = "/options";
export const COLOR = "/color";
export const INSTANTOFFER = "/instant-offer";

export const ALL_LISTING = "/all-listing";
export const CAR_CATEGORY_ID = "?car_category_id";
export const MAKE_ID = "&make_id";
export const MODEL_ID = "&model_id";









