import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SpinnerCircular } from "spinners-react";
import "../../assets/css/profile.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { UpdateProfile } from "../../network/Network";
import { StoreUserInfo } from "../../redux/actions/AuthActions";
import EditUserInfo from "./EditUserInfo";
import UserInfo from "./UserInfo";

const EditProfile = () => {
	const dispatch = useDispatch()
	const Navigate = useNavigate()
	const UserData = useSelector((state) => state.AuthReducer.users);
	const Token = useSelector((state) => state.AuthReducer.token);
	const [editLoading, setEditLoading] = useState(false)
	const [profileData, setProfileData] = useState({
		first_name: UserData?.first_name ? UserData?.first_name : " ",
		last_name: UserData?.last_name ? UserData?.last_name : " ",
		website:  UserData?.website ? UserData?.website : " ",
		bio: UserData?.bio ? UserData?.bio : " ",
		strain: UserData?.strain ? UserData?.strain : " ",
		phone_no: UserData?.phone_no ? UserData?.phone_no : " ",
		country: UserData?.country ? UserData?.country : " ",
	})

	const [newFile, setNewFile] = useState("");
	const [fileupload, setFileupload] = useState("");
	const [uploadLoading, setUploadLoading] = useState(false);

	const handleImageUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setNewFile(file);
			setFileupload(reader.result);
		};
		reader.readAsDataURL(file);
		setUploadLoading(true);
	};

	function handleChange(e) {
		const value = e.target.value;
		setProfileData({
		  ...profileData,
		  [e.target.name]: value
		});
	  }

	const EditProfileHandler = (e) => {
		e.preventDefault();
		setEditLoading(true);
		if (!profileData?.first_name || !profileData?.last_name) {
		  setEditLoading(false);
		  toast.error("Please Enter All Field");
		  return;
		}
		if (profileData?.phone_no?.length < 10) {
			toast.error("The phone no must be at least 10 characters");
			setEditLoading(false);
			return;
		  }

		let data = new FormData();
		data.append("first_name", profileData?.first_name);
		data.append("last_name", profileData?.last_name);
		data.append("profile_picture", newFile ? newFile : UserData?.image_url );
		data.append("website", profileData?.website);
		data.append("bio", profileData?.bio);
		data.append("strain", profileData?.strain);
		data.append("phone_no", profileData?.phone_no);
		data.append("country", profileData?.country);
		console.log(data);
		UpdateProfile(data, Token)
		  .then((res) => {
			setEditLoading(false);
			setUploadLoading(false);
			toast.success(res?.data?.message);
			console.log("edit prf",res);
			dispatch(StoreUserInfo(res?.data?.response?.data))
			Navigate('/profile')
		  })
		  .catch((err) => {
			console.log(err);
			setEditLoading(false);
			setUploadLoading(false);
		  });
	  };

	return (
		<>
			{/* Header */}
			<Header />
			{/* Header */}

			<section className="edit-profile">
				<div className="container">
					<div className="topbar">
						<EditUserInfo 
							uploadLoading={uploadLoading}
							fileupload={fileupload}
							handleImageUpload={handleImageUpload}
						/>
					</div>
					<div className="row align-items-center">
						<div className="col-md-1"></div>
						<div className="col-md-10">
							<div className="edit-profile-form">
								<div className="close-btn">
									<Link to="/profile">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="currentColor"
											className="bi bi-x"
											viewBox="0 0 16 16"
										>
											<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
										</svg>
									</Link>
								</div>
								<form action="/profile">
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>First Name</label>
												<input
													type="text"
													className="form-control"
													placeHolder="First Name"
													name="first_name"
													value={profileData?.first_name}
													onChange={handleChange}
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Last Name</label>
												<input
													type="text"
													className="form-control"
													placeholder="Last Name"
													name="last_name"
													value={profileData?.last_name}
													onChange={handleChange}
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Favorite Strain</label>
												<input
													type="text"
													className="form-control"
													placeholder="Strain"
													name="strain"
													value={profileData?.strain}
													onChange={handleChange}
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Country</label>
												<input
													type="text"
													className="form-control"
													placeholder="Country"
													name="country"
													value={profileData?.country}
													onChange={handleChange}
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Website</label>
												<input
													type="text"
													className="form-control"
													placeholder="www.website.com"
													name="website"
													value={profileData?.website}
													onChange={handleChange}
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Bio</label>
												<input
													type="text"
													className="form-control"
													placeholder="your bio"
													name="bio"
													value={profileData?.bio}
													onChange={handleChange}
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Email</label>
												<input
													type="email"
													value={UserData?.email}
													className="form-control"
													disabled={true}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Phone</label>
												<input
													type="number"
													className="form-control"
													placeholder="+123456789"
													name="phone_no"
													value={profileData?.phone_no}
													onChange={handleChange}
													required
												/>
											</div>
										</div>
										{/* <div className="col-md-6">
											<div className="form-group">
												<label>Password</label>
												<input
													type="password"
													value="12345678"
													className="form-control"
												/>
											</div>
										</div> */}
										<div className="col-md-12">
											<div className="button-group form-group">
												<button className="btn" type="submit"
													onClick={(e)=>EditProfileHandler(e)}
													disabled={editLoading}
												>
													{editLoading ?  <SpinnerCircular size="20px" color="#fff" /> : "Save" }
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="col-md-1"></div>
					</div>
				</div>
			</section>

			{/* Footer */}
			<Footer />
			{/* Footer */}
		</>
	);
};

export default EditProfile;
